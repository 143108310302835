import React, { PureComponent, Component } from 'react'
import MyInput from './MyInput'
import MyButton from './myButton'
import ToastMSG from './toastMSG'
export class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token :"",
            error:false,
            msgError:''

        }
        this.setToken = this.setToken.bind(this);
        this.onTokenchange = this.onTokenchange.bind(this);
        this.propOpenFunc = this.propOpenFunc.bind(this)
    }
    async componentDidMount()
    {
        await this.setState({token:JSON.parse(localStorage.getItem('wenk_helper_token'))})
    }

    render() {
        return (
            <div>
                <div className="App">
                    <MyInput hint="Token" label="Token" value={this.state.token} valChange={this.onTokenchange}></MyInput>
                    <MyButton label="Set" click={this.setToken}></MyButton>
                   {this.state.error?  <ToastMSG propOpen={this.propOpenFunc} open={this.state.error} msg={this.state.msgError}></ToastMSG>:null}
                </div>
            </div>
        )
    }
    propOpenFunc(e)
    {
        // console.log('proping')
        this.setState({error:! this.state.error})
        console.log('propbed')

    }
    onTokenchange(e) {
        this.setState({ token: e.target.value })
        // console.log('token changing')
      }
    
      setToken(e) {
        e.preventDefault()
        if (!this.state.token) {
            this.setState({error:true,msgError:'You have to enter the token'})
            return;
        }
        else {
          if (this.state.token.length < 72) {
            this.setState({error:true,msgError:'You token is short than 72 chars!'})
            return;
          }

          localStorage.setItem('wenk_helper_token', JSON.stringify(this.state.token));
          this.setState({error:true,msgError:'Token Saved Successfully'})
          
    
        }
      }
}

export default Settings
