import React, { Component } from 'react';
import logo from './logo.svg';
import MyNav from './components/MyNav'
import { BrowserRouter, Route, Switch, Router } from 'react-router-dom';
import Context from './components/context'
import Settings from './components/Settings'
import { MDBBtn } from 'mdbreact';
import './App.css';


/* global wialon */



function msg(msg) {
  // console.log(msg);
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: '',
      isAuthorized: false,
      res: [],
      hdTypes: [],
      logging: true,
      error: false,
      msgError: '',
      msgarray :[],
      driversList: [],
      selectedRes: {},
      currentProgress:0,
      uploading:false

    }
    this.handleFiles = this.handleFiles.bind(this)
    this.createDriver = this.createDriver.bind(this)
    this.selectResource = this.selectResource.bind(this)
    this.propOpenFunc = this.propOpenFunc.bind(this)
    this.driverPatch = this.driverPatch.bind(this)
  }
  driverPatch(e)
  {
    // empty the msg error first
    if(this.state.msgarray && this.state.msgarray.length>0)
    {
      this.setState({error:true,msgError:'There are errors in uploaded file!'})
      return;
    }
    if (Object.keys(this.state.selectedRes).length === 0 && this.state.selectedRes.constructor === Object) {
      this.setState({ error: true, msgError: 'Select resource first!' })
      return;
    }

    if (!wialon.util.Number.and(this.state.selectedRes.getUserAccess(), wialon.item.Resource.accessFlag.editDrivers)) {
      this.setState({ error: true, msgError: 'You don\'t have access to edit drivers in' + this.state.selectRes.getName() })
      return;
    }
    this.setState({uploading:true})
    if(this.state.driversList && this.state.driversList.length > 0)
    {
      let i = 0;
    

      this.uploadDriverRec(this.state.driversList,i)
    }
    else{
      this.setState({error:true,msgError:'Driver List is empty!'})
    }
  }
  selectResource(selectedResObj) {
    this.setState({ selectedRes: selectedResObj })
  }

  uploadDriverRec(arr,i)
  {
    this.setState({currentProgress:i+1})
      let driverObject = {
        "itemId": this.state.selectedRes, // resourceId
        "id": 0, // item_id
        "callMode": "create",
        "c": arr[i].code, // driver code
        "ck": 0, // image checksum
        "ds": "", // description
        "n": arr[i].name, // name
        "p": arr[i].phone1, // phone
        "r": 1, // image aspect ratio
        "f": 0, // flags
        "jp": arr[i].phone2.length == 0? {} : { 'phone 2':arr[i].phone2 } // additional fields
      }
      let that = this
      this.setState({msgArray:[]})
      this.state.selectedRes.createDriver(driverObject, function (code, data) {
           if(code)
           {
             
             let msg  = 'Error : Code :'+ code +', details : '+ wialon.core.Errors.getErrorText(code) + 'driver name : '+driverObject.n ;
            //  console.log(msg + i)
             let arrTemp = that.state.msgArray;
             arrTemp.push({line:i+1,errorMSG:msg})
             that.setState({msgArray:arrTemp})
           }
  
           i = i+1
           if(i < arr.length)
           {
             that.uploadDriverRec(arr,i)

           }
           else{
              that.setState({uploading:false})

            // console.log('success!')
            if(that.state.msgArray && that.state.msgArray.length > 0)
            {
              // console.log('completed!')
             that.setState({error:true,msgError:'uploading completed with an errors!'})
            }
            else{
              // console.log('completed!')

             that.setState({error:true,msgError:'uploading completed!'})
 
            }
           }
          
      });
  
 
  }


  handleFiles(files) {
    // console.log('from app ')
    // console.log(files)
    let that = this
    let reader = new FileReader(); // no arguments

    reader.readAsText(files[0], 'UTF-8')
    reader.onload = function () {
      // console.log(reader.result);
      let driversData = reader.result.split('\n')
      let jsonData = that.createDriversObjects(driversData)
      let errorsarray = that.validateData(jsonData);
      if (errorsarray.length > 0) {
        
        that.setState({ error: true,msgError: 'invalid-file',msgArray:errorsarray,driversList:[]})
        return;
      }

      that.setState({ error: false,driversList: jsonData ,msgArray:[]})
    };

    reader.onerror = function () {
      // console.log(reader.error);
    };
  }
  validateData(jsonData) {
    let errorMsg = []
    for (let i = 0; i < jsonData.length; i++) {
      if(jsonData[i].id == '' || jsonData[i].name === '----')
      {
        errorMsg.push({ line: i + 1, errorMSG: 'Line empty or have some invalid data!' })
        continue;
      }
     

      if (jsonData[i].phone1.length < 11) {
        errorMsg.push({ line: i + 1, errorMSG: 'invalid phone1 number : number is short' })

      } else {
        if (!jsonData[i].phone1.startsWith('+964')) {
          errorMsg.push({ line: i + 1, errorMSG: 'invalid phone1 number : international key required!' })
        }
      }

      if (jsonData[i].phone2.length > 10) {
        if (jsonData[i].phone2.length < 11) {
          errorMsg.push({ line: i + 1, errorMSG: 'invalid phone2 number : number is short' })

        }
        else {
          if (!jsonData[i].phone2.startsWith('+964')) {
            errorMsg.push({ line: i + 1, errorMSG: 'invalid phone2 number : international key required!' })
          }
        }

      }
      else{
        if(jsonData[i].phone2.length>0)
        {
          errorMsg.push({ line: i + 1, errorMSG: 'invalid phone2 number : invalid format' })

        }
      }


    }
    return errorMsg;
  }

 

  createDriverObject(line) {

    let objectArr = line.split(',');
    if (objectArr.length != 5) {
      return {
        'id': objectArr[0],
        'name': '----',
        'phone1': '-----------',
        'phone2': '-----------',
        'code': '',
        'status': '',
        'action': <MDBBtn color="purple" size="sm">------</MDBBtn>

      }
    }
    return {
      'id': objectArr[0],
      'name': objectArr[1],
      'phone1': objectArr[2],
      'phone2': objectArr[3],
      'code': objectArr[4],
      'status': 'pending',
      'action': <MDBBtn onClick={() => { this.createDriver(objectArr) }} color="purple" size="sm">Create</MDBBtn>

    }
  }
  createDriver(obj) {
    if (Object.keys(this.state.selectedRes).length === 0 && this.state.selectedRes.constructor === Object) {
      this.setState({ error: true, msgError: 'Select resource first!' })
      return;
    }

    if (!wialon.util.Number.and(this.state.selectedRes.getUserAccess(), wialon.item.Resource.accessFlag.editDrivers)) {
      this.setState({ error: true, msgError: 'You don\'t have access to edit drivers in' + this.state.selectRes.getName() })
      return;
    }

    let driverObject = {
      "itemId": this.state.selectedRes, // resourceId
      "id": 0, // item_id
      "callMode": "create",
      "c": obj[4], // driver code
      "ck": 0, // image checksum
      "ds": "", // description
      "n": obj[1], // name
      "p": obj[2], // phone
      "r": 1, // image aspect ratio
      "f": 0, // flags
      "jp": obj[3].length ===0 ? {} : { 'phone 2':obj[3] } // additional fields
    }
    let that = this
    // console.log('driver object to be created is ')
    // console.log(driverObject)
    this.state.selectedRes.createDriver(driverObject, function (code, data) {
         if(code)
         {
           that.setState({error:true,msgError:'Error : Code :'+ code +', detalis : '+ wialon.core.Errors.getErrorText(code)})
           return;
         }

         that.setState({error:true,msgError:'Driver Created Successfully'})

    });

  }

  createDriversObjects(arr) {
    let driverArrObjects = []
    for (var i = 0; i < arr.length; i++) {
      driverArrObjects.push(this.createDriverObject(arr[i]))
    }
    return driverArrObjects;
  }
  async componentDidMount() {
    await this.setState({ token: JSON.parse(localStorage.getItem('wenk_helper_token')), logging: true })
    wialon.core.Session.getInstance().initSession("https://hst-api.wialon.com");
    // Try to login when component mount
    wialon.core.Session.getInstance().loginToken(
      this.state.token,
      "", // try to login
      async code => {
        // login callback
        // if error code - print error message
        if (code) {
          this.setState({ logging: false, isAuthorized: false, error: true, msgError: wialon.core.Errors.getErrorText(code) })
          return;
        }
        this.setState({ logging: false, isAuthorized: true, error: true, msgError: 'Login successfully!' })
        await this.init()
      }
    );
  }
  init() {
    let that = this
    var sess = wialon.core.Session.getInstance();

    sess.loadLibrary("itemProfileFields");
    sess.loadLibrary("resourceDrivers");
    // flags to specify what kind of data should be returned
    var flags = wialon.item.Item.dataFlag.base | wialon.item.Item.dataFlag.profileFields | wialon.item.Item.dataFlag.customFields | wialon.item.Resource.dataFlag.drivers | wialon.item.Resource.dataFlag.base// 64 bit OR
    // console.log('flags -> data should be returned is : ' + flags)
    // Subscribe on resource data
    sess.updateDataFlags( // load items to current session
      [
        { type: "type", data: "avl_resource", flags: flags, mode: 0 },
        { type: "type", data: "avl_unit", flags: flags, mode: 0 }
      ], // Items specification

      function (code) { // updateDataFlags callback 
        if (code) {
          // console.log("Error: " + wialon.core.Errors.getErrorText(code));
          return; // exit if error code 
        }

        const resourcesArr = sess.getItems("avl_resource"); // get loaded 'avl_resource's items
        // console.log('hw1')

        sess.getHwTypes({ filterType: "type", filterValue: ['tracker', 'auto', 'mobile', 'soft'], includeType: true }, async function (code, col) {

          // console.log('code is ' + code)
          // console.log('hw2')

          // console.log(col)
          that.setState({ hdTypes: col })

        });

        that.setState({ res: resourcesArr })
        // console.log(that.state)
      });
  }
  render() {
    // console.log('Started')
    return (
      // <MyNav></MyNav>
      <BrowserRouter basename="/">
        <Context.Provider value={{
          value: this.state,
          action: {
            handleFiles: this.handleFiles,
            selectResource: this.selectResource,
            propOpenFunc: this.propOpenFunc,
            driverPatch  : this.driverPatch
          }
        }} >
          <Switch>
            <Route exact path='/' component={MyNav} />
            <Route path='/Create' component={MyNav} />
            <Route path='/Settings' component={MyNav} />
            <Route path='/UploadDrivers' component={MyNav} />
            <Route path='/Set' component={Settings} />
          </Switch>
        </Context.Provider>
      </BrowserRouter>
    );
  }

  propOpenFunc(e) {
    this.setState({ error: !this.state.error, msgError: '' })
  }


}

export default App;
