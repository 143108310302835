import React, { Component } from 'react';
import Context from './context';
import MyInput from './MyInput'
import MyButton from './myButton'
import ToastMSG from './toastMSG'
import { Combobox } from 'evergreen-ui'
/* global wialon */

class Create extends Component {
    constructor(props) {
        super(props)
        this.state = {
            unitInfo: '',
            error: '',
            msgError: '',
            currentUser: {},
            selectedHWID: 0,
        }
        this.onUnitInfoChange = this.onUnitInfoChange.bind(this)
        this.create = this.create.bind(this)
        this.propOpenFunc = this.propOpenFunc.bind(this)
        this.onHardwareSelected = this.onHardwareSelected.bind(this)
    }
    componentDidMount() {

    }
    render() {
        return (
            <Context.Consumer>
                {
                    ctx => {
                        return (
                            <div className="unitForm">
                                <Combobox
                                    className="selectClass Aligner-item"
                                    width="300px"
                                    items={ctx.value.res}
                                    onChange={
                                        selected => 
                                        console.log('')
                                    }
                                    placeholder="Resource"
                                    itemToString={item => item ? item.getName() : ''}
                                    autocompleteProps={{
                                        // Used for the title in the autocomplete.
                                        title: 'Searching'
                                    }}
                                />
                                <Combobox
                                    items={ctx.value.hdTypes}
                                    width="300px"
                                    className="selectClass Aligner-item"

                                    onChange={this.onHardwareSelected}
                                    itemToString={item => item ? item.name : ''}

                                    placeholder="Hardware types"
                                    autocompleteProps={{
                                        // Used for the title in the autocomplete.
                                        title: 'Searching'
                                    }}
                                />
                                <MyInput hint="Name;VIN;Brand;Model;Year" label="Unit Info" value={this.state.unitInfo} valChange={this.onUnitInfoChange}></MyInput>

                                <MyButton label="Create" click={this.create}></MyButton>
                                {this.state.error ? <ToastMSG propOpen={this.propOpenFunc} open={this.state.error} msg={this.state.msgError}></ToastMSG> : null}
                            </div>

                        );
                    }
                }
            </Context.Consumer>
        );
    }
    propOpenFunc(e) {
        this.setState({ error: !this.state.error })
    }
    onUnitInfoChange(e) {
        // console.log(e.target.value)
        this.setState({ unitInfo: e.target.value })
    }
    onHardwareSelected(e) {
        if (e) {
            this.setState({ selectedHWID: e.id })
        }
    }

    create(e) {

        if (!this.state.unitInfo) {
            this.setState({ error: true, msgError: 'check your input please!' })
            return;
        }
        let infoArr = this.state.unitInfo.split(';');
        if (infoArr.length !== 5) {
            this.setState({ error: true, msgError: 'check number of your input parts ' })
            return;
        }

        if (this.state.selectedHWID === 0) {
            this.setState({ error: true, msgError: 'You have to select hardware type ' })
            return;
        }
        if (infoArr[0].length < 4) {
            this.setState({ error: true, msgError: 'Unit name should be > 4 chars!' })
            return;
        }
        if (infoArr[1].length < 1 || infoArr[2].length < 1 || infoArr[3].length < 1 || infoArr[4].length < 1) {
            this.setState({ error: true, msgError: 'VIN, Brand, Model, Year should be > 1 char!' })
            return;
        }
        if (isNaN(infoArr[4])) {
            this.setState({ error: true, msgError: 'Year should be a number' })
            return;
        }
        // now create the unit
        let sess = wialon.core.Session.getInstance();
        this.setState({ error: false, msgError: '' })
        let that = this
        if (!sess.isInitialized()) {
            that.setState({ error: true, msgError: 'You need to reload the page, session invalid' })
            return;
        }
        var flags = wialon.item.Item.dataFlag.base | wialon.item.Item.dataFlag.profileFields | wialon.item.Item.dataFlag.customFields | wialon.item.Resource.dataFlag.drivers | wialon.item.Resource.dataFlag.base// 64 bit OR

        sess.createUnit(sess.getCurrUser(), infoArr[0], this.state.selectedHWID, flags, function (code, obj) {
            if (code === 0) {
                let keys = ['registration_plate', 'vin', 'brand', 'model', 'year']
                let values = infoArr
                let i = 0
                that.updateProfileFields(obj, keys, values, i)
            }
            else {

                that.setState({ error: true, msgError: "Error: " + wialon.core.Errors.getErrorText(code) })

            }

        })
    }

    updateProfileFields(unit, keys, values, i) {
        let that = this
        unit.updateProfileField(keys[i], values[i], function (code, col) {
            if (code !== 0) {
                that.setState({ error: true, msgError: 'Profile Field did not updated' + wialon.core.Errors.getErrorText(code) })
                return;
            }

            i = i + 1
            if (i < 5) {
                that.updateProfileFields(unit, keys, values, i)
            }
            else {
                that.setState({ error: true, msgError: 'Unit created' })
                return;
            }


        })

    }
}

export default Create;