import React from 'react';
import { MDBBtn, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import Context from './context'

const columns = [
    {
        label: '#',
        field: 'id',
        sort: 'asc'
    },
    {
        label: 'Name',
        field: 'name',
        sort: 'asc'
    },
    {
        label: 'Phone 1',
        field: 'phone1',
        sort: 'asc'
    },
    {
        label: 'Phone 2',
        field: 'phone2',
        sort: 'asc'
    },
    {
        label: 'Code',
        field: 'code',
        sort: 'asc'
    },
    {
        label: 'Status',
        field: 'status',
        sort: 'asc'
    },
    {
        label: 'Action',
        field: 'action',
        sort: 'asc'
    }
];

class TablePage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    componentDidMount() {

    }
    render() {
        return (
            <Context.Consumer>
                {
                    ctx => {

                        return (
                            <div>
                                <MDBTable  btn>
                                    <MDBTableHead columns={columns} />
                                    <MDBTableBody rows={ctx.value.driversList} />
                                </MDBTable>
                            </div>
                        )
                    }
                }
            </Context.Consumer>


        );
    }




}

export default TablePage;