import React from 'react'
import Context from './context'
import ReactFileReader from 'react-file-reader';
import MyTable from './MTable'
import { Combobox, Spinner } from 'evergreen-ui'
import ToastMSG from './toastMSG'
import MyButton from './myButton'
import MySpinner from './spinner'
export default function UploadDrivers() {
    return (
        <Context.Consumer>
            {
                ctx => {
                    return (
                        <div>

                            <div className="res-spinner-div">

                                <Combobox
                                className="selectClass Aligner-item"
                                width="300px"
                                items={ctx.value.res}
                                onChange={selected => ctx.action.selectResource(selected)}
                                placeholder="Resource"
                                itemToString={item => item ? item.getName() : ''}
                                autocompleteProps={{
                                    // Used for the title in the autocomplete.
                                    title: 'Searching'
                                }}
                            />
                           {
                               ctx.value.uploading?  <MySpinner></MySpinner> :null
                           }

                            </div>
                            <MyButton label="Start Patch" click={ctx.action.driverPatch}></MyButton>

                            <ReactFileReader fileTypes={[".csv"]} handleFiles={ctx.action.handleFiles}>
                                <button className='btn'>Upload</button>
                            </ReactFileReader>
                            {/* {ctx.value.driversList && ctx.value.driversList.length > 0 ?
                                ctx.value.msgArray && ctx.value.msgArray.length>0 ? (<MyTable></MyTable>) : null
                                 :
                                ctx.value.msgArray && ctx.value.msgArray.length > 0 ?
                                    ctx.value.msgArray.map((obj, index) => {
                                        return (<div key={index}>  Line : {obj.line}, message : {obj.errorMSG}  </div>)

                                    })
                                    : null


                            } */}
                            {
                                ctx.value.driversList && ctx.value.driversList.length > 0 ? (<MyTable></MyTable>) : null
                            }
                            {
                                ctx.value.msgArray && ctx.value.msgArray.length > 0 ?
                                    ctx.value.msgArray.map((obj, index) => {
                                        return (<div key={index}>  Line : {obj.line}, message : {obj.errorMSG}  </div>)

                                    })
                                    : null
                            }
                            {ctx.value.error ? <ToastMSG propOpen={ctx.action.propOpenFunc} open={ctx.value.error} msg={ctx.value.msgError}></ToastMSG> : null}


                        </div>

                    );
                }
            }

        </Context.Consumer>
    )
}


