import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import { Link, NavLink } from 'react-router-dom'
import Create from './Create'
import Settings from './Settings'
import UploadDrivers from './UploadDrivers'
import { Redirect } from 'react-router-dom';
import Context from './context'
import MyProgress from './progess'
import LoadingOverlay from 'react-loading-overlay';



const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}));

const renderPage = (props) => {
    // console.log(props)
    if (props.match.path === '/Create') {
        return (<Create />)
    }
    if (props.match.path === '/UploadDrivers') {
        return (<UploadDrivers />)
    }

    if (props.match.path === '/Settings') {
        return (<Settings />)
    }
    if (props.match.path === '/') {
        return (<Create />)
    }


}

/* global wialon */

function msg(msg) {
    // console.log(msg);
}
export default function PersistentDrawerLeft(props) {


    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };


    return (
        <Context.Consumer>

            {
                ctx => {
                    // console.log(ctx)
                    if (ctx.value.isAuthorzied === false) {
                        return (<Redirect to="/Set"></Redirect>)
                    }
                    return (

                        <div className={classes.root}>
                            <CssBaseline />
                            <AppBar
                                position="fixed"
                                className={clsx(classes.appBar, {
                                    [classes.appBarShift]: open,
                                })}
                            >
                                <Toolbar>
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        onClick={handleDrawerOpen}
                                        edge="start"
                                        className={clsx(classes.menuButton, open && classes.hide)}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                    <Typography variant="h6" noWrap>
                                        {rendertitile(props)}
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <Drawer
                                className={classes.drawer}
                                variant="persistent"
                                anchor="left"
                                open={open}
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                            >
                                <div className={classes.drawerHeader}>
                                    <IconButton onClick={handleDrawerClose}>
                                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                                    </IconButton>
                                </div>
                                <Divider />
                                {
                                    ctx.value.logging ? null : <div>

                                        <NavLink to='/Create' activeClassName='active' >
                                            <List className='sidefect' style={{ paddingBottom: 0 }} >

                                                <ListItem >
                                                    <img src={require('../assets/img/wenklogo.png')} alt='img' style={{ width: "25px", height: "25px" }} id='side_img' />
                                                    <ListItemText ><span className='sspan' style={{ fontWeight: '500', fontSize: '18px' }}>Create Unit</span></ListItemText>
                                                </ListItem>

                                            </List>
                                        </NavLink>

                                        <NavLink to='/UploadDrivers' activeClassName='active' >
                                            <List className='sidefect' style={{ paddingBottom: 0 }} >

                                                <ListItem >
                                                    <img src={require('../assets/img/wenklogo.png')} alt='img' style={{ width: "25px", height: "25px" }} id='side_img' />
                                                    <ListItemText ><span className='sspan' style={{ fontWeight: '500', fontSize: '18px' }}>Upload Drivers</span></ListItemText>
                                                </ListItem>

                                            </List>
                                        </NavLink>

                                        <NavLink to='/Settings' activeClassName='active' >
                                            <List className='sidefect' style={{ paddingBottom: 0 }} >

                                                <ListItem >
                                                    <img src={require('../assets/img/wenklogo.png')} alt='img' style={{ width: "25px", height: "25px" }} id='side_img' />
                                                    <ListItemText ><span className='sspan' style={{ fontWeight: '500', fontSize: '18px' }}>Settings</span></ListItemText>
                                                </ListItem>

                                            </List>
                                        </NavLink>
                                    </div>

                                }


                            </Drawer>
                            <main

                                className={clsx(classes.content, {
                                    [classes.contentShift]: open,
                                })}
                            >
                                <div className={classes.drawerHeader} />
                               
  
                                <LoadingOverlay
                                    active={ctx.value.uploading}
                                    spinner
                                    text='Saving drivers to wialon...'
                                >
                                    {ctx.value.logging ? <MyProgress> </MyProgress> : renderPage(props)}

                                </LoadingOverlay>
                               
                            </main>
                        </div>
                    );

                }
            }
        </Context.Consumer>

    );



}
function rendertitile(props) {
    if (props.match.path === '/Create') {
        return (<div> Create New Unit </div>)
    }

    else if (props.match.path === '/UploadDrivers') {
        return (<div>  Dricers info patch  </div>)
    }
    else if (props.match.path === '/Settings') {
        return (<div> Settings </div>)
    }
}
