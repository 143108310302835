import React from "react";
import Context from './context'
const SpinnerPage = () => {
    return (
        <Context.Consumer>
            {
                ctx => {
                    return (

                        <div className="res-spinner-di">
                            <div className="spinner-grow text-primary" role="status">
                                <span className="sr-only">Uploading.... ({ctx.value.currentProgress})</span>
                            </div>
                            {
                                ctx.value.currentProgress > 0? 
                            <span >Uploading.... ({ctx.value.currentProgress})</span>
                            :
                            null

                            }
                        </div>
                    )
                }
            }
        </Context.Consumer>


    );
}

export default SpinnerPage;