import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export default function OutlinedButtons(props) {
  const classes = useStyles();
  // console.log('passed props')
  // console.log(props)
  return (
    <div className={classes.root}>
      <Button variant="outlined" color="primary" onClick={props.click}>
        {props.label}
      </Button>
      
    </div>
  );
}